import { CSSProperties, FC } from 'react';

import { hasRichTextContent } from '@lib/slice-machine/richText';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, Grid, Stack } from '@mui/material';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';

import { CardTilesVariation } from '../types';
import { Tile } from './Tile';
import { DEFAULT_SPACING_BETWEEN_ELEMENTS } from '../../constants';
import { SliceLayout } from '@ui/slice-machine/SliceLayout';

interface Props {
  slice: CardTilesVariation;
}

export const CardTiles: FC<Props> = ({ slice }) => {
  const {
    title,
    description,
    textAlign = 'left',
    alignButton = 'center',
    buttonTextLink,
    buttonBackgroundColor,
    buttonTextColor,
    buttonVariant,
    backgroundColor,
    paddingTop,
    paddingBottom,
    width,
  } = slice.primary;
  const styles = getStyles(textAlign);
  const hasButton = hasRichTextContent(buttonTextLink);

  return (
    <SliceLayout
      backgroundColor={backgroundColor}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      width={width}
    >
      <Stack spacing={DEFAULT_SPACING_BETWEEN_ELEMENTS}>
        {hasRichTextContent(title) && (
          <CustomRichText
            containerSx={styles.getValue('title')}
            render={title}
          />
        )}
        {hasRichTextContent(title) && (
          <CustomRichText
            containerSx={styles.getValue('description')}
            render={description}
          />
        )}
        <Box>
          <Grid container spacing={2}>
            {slice?.items?.map((tile, i) => (
              <Grid key={i} item xs={12} md={3}>
                <Tile {...tile} />
              </Grid>
            ))}
          </Grid>
        </Box>
        {hasButton && (
          <Box sx={{ textAlign: alignButton }}>
            <CustomRichText
              render={buttonTextLink}
              linkBtnProps={{
                linkAsButton: true,
                variant: buttonVariant ?? undefined,
                color: buttonBackgroundColor ?? 'primary',
                textProps: {
                  color: buttonTextColor ?? 'light.main',
                  fontSize: 18,
                },
              }}
            />
          </Box>
        )}
      </Stack>
    </SliceLayout>
  );
};

const getStyles = (textAlign: CSSProperties['textAlign']) => {
  return new SxStyles({
    title: {
      textAlign,
    },
    description: {
      textAlign,
    },
  });
};
