import { FC } from 'react';

import { DefaultTiles } from './default-slice/DefaultTiles';
import {
  DefaultTilesVariation,
  TilesVariations,
  CardTilesVariation,
} from './types';
import { CardTiles } from './card-tiles/CardTiles';

export interface TilesSliceProps {
  slice: TilesVariations;
}

export const TilesSlice: FC<TilesSliceProps> = (props) => {
  const { slice } = props;

  switch (slice.variation) {
    case 'card-tiles':
      return <CardTiles slice={slice as CardTilesVariation} />;
    default:
      return <DefaultTiles slice={slice as DefaultTilesVariation} />;
  }
};
